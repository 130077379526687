<template>
  <ValidationObserver v-slot="{ handleSubmit }" tag="div" ref="form" class="form">
    <v-form @submit.prevent="handleSubmit(submitHandler)">
      <ValidationProvider class="mb-7" mode="eager" tag="div"
                          name="space name" vid="name" rules="max:128|required" v-slot="{ errors }">
        <div class="mb-2 text-body font-weight-light gray-100--text">Name</div>
        <v-text-field
          dense
          outlined
          hide-details
          v-model.trim="internalValue.title"
          :error="!!errors.length"
          placeholder="Enter space name"
        />
        <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
      </ValidationProvider>

      <ValidationProvider class="mb-7" mode="eager" tag="div"
                          name="icon color" vid="icon">
        <div class="mb-2 text-body font-weight-light gray-100--text">Icon</div>
        <UiColorPicker v-model="internalValue.color" placeholder="Icon color"/>
      </ValidationProvider>
      <slot name="action"/>
    </v-form>
  </ValidationObserver>
</template>

<script>

import UiColorPicker from "@/components/UI/UiColorPicker";

export default {
  name: "SpaceCreateForm",
  components: {UiColorPicker},
  props: {
    value: {
      type: Object,
      required: true,
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    submitHandler() {
      this.$emit('submit', this.internalValue)
    },
  },
}
</script>


<style scoped lang="scss">
.form{
  max-width: 262px;
}
</style>
