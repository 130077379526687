<template>
  <div>
    <div class="text-body-lg font-weight-medium mb-5">Information</div>
    <SpaceCreateForm v-model="form" @submit="onSubmit">
      <template #action>
        <div class="d-flex align-center space-x-2">
          <UiBtn class="flex-fill fill-width" :to="{name: 'Dashboard'}" outlined color="accent">Cancel</UiBtn>
          <UiBtn class="flex-fill fill-width" color="accent" type="submit" :disabled="!getUser.email_verified" :loading="loading">
            {{ duplicatingSpace ? 'Finish' : 'Continue' }}
          </UiBtn>
        </div>
      </template>
    </SpaceCreateForm>
  </div>
</template>

<script>
import SpaceCreateForm from "@/components/spaces/SpaceCreateForm";
import UiBtn from "@/components/UI/UiBtn";
import {mapGetters} from "vuex";

export default {
  name: "SpaceCreateView",
  components: {UiBtn, SpaceCreateForm},
  props: {
    step: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      form: {
        title: '',
        color: ''
      },
    }
  },
  computed: {
    ...mapGetters([
      'getSpaces',
      'getUser'
    ]),
    duplicatingSpace() {
      return this.getSpaces.find(space => space.id === +this.$route.query.duplicate_from) || null
    }
  },
  watch: {
    '$route.query.duplicate_from': {
      handler() {
        if (this.duplicatingSpace) {
          this.form.color = this.duplicatingSpace.color
          this.form.title = `Copy of "${this.duplicatingSpace.title}"`
        }
      },
      immediate: true
    },
  },
  methods: {
    async onSubmit(data) {
      this.loading = true
      try {
        const space = this.duplicatingSpace ? await this.$api.spaces.duplicateSpace(this.duplicatingSpace.id, data) : await this.$api.spaces.create({
          ...data,
          scheme_id: 1
        })
        await this.$store.dispatch('setSpaces', [...this.getSpaces, space.data])
        await this.$router.replace({name: 'SpaceAddMembersView', params: {id: space.data.id}, query: this.$route.query})
        this.$toast.open({
          message: `Congratulations,you have successfully <br> created your space.`,
          type: 'success',
          position: 'top-right'
        });
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

