<template>
  <v-text-field
    dense
    outlined
    hide-details
    height="30"
    :value="color"
    @change="onUpdateColor"
    @keydown.enter.prevent
    class="color-picker-input"
    :placeholder="placeholder"
  >
    <template #prepend-inner>
      <v-menu offset-y nudge-top="-7px" content-class="color-picker-menu pa-4 pb-0" :close-on-content-click="false">
        <template #activator="{on, attrs}">
          <v-sheet :color="color" width="16" height="16" rounded="circle" v-on="on" v-bind="attrs"/>
        </template>
          <v-color-picker canvas-height="232" width="232" v-model="color" hide-mode-switch hide-inputs ref="picker"></v-color-picker>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>

const DEFAULT_COLOR = '#1DCA62'
export default {
  name: "UiColorPicker",
  props: {
    value: {
      type: String,
      default: DEFAULT_COLOR
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  watch: {
    color: {
      handler() {
        const reg=/^#([0-9a-f]{3}){1,2}$/i
        const isValid = reg.test(this.color)
        this.$emit('input', isValid ? this.color: DEFAULT_COLOR)
      },
      immediate: true
    }
  },
  computed: {
    color: {
      get() {
        return this.value.toUpperCase()
      },
      set(value) {
        this.$emit('input', value.toUpperCase())
      },
    },
  },
  methods: {
    onUpdateColor(color) {
      if(typeof color === 'string') {
        if(color.startsWith('#')) {
          this.color = color
        } else {
          this.color = `#${color}`
        }
      } else {
        this.color = DEFAULT_COLOR
      }
    },
  },
}
</script>

<style scoped lang="scss">
 .color-picker-input :deep(.v-input__prepend-inner) {
   margin-top: auto !important;
   margin-bottom: auto;
   margin-right: 8px;
 }
 .color-picker-menu{
   box-shadow: 0px 10px 15px rgba(31, 41, 55, 0.1), 0px 4px 6px rgba(31, 41, 55, 0.05);
   background: #ffffff;
   :deep(.v-color-picker__canvas){
     border-radius: 5px;
   }
   :deep(.v-color-picker__controls){
     padding: 16px 0;
     .v-color-picker__dot{
       display: none;
     }
     .v-slider{
       min-height: 8px;
     }
     .v-slider__thumb{
       background-color: transparent !important;
       box-shadow: none;
       border: 2px solid #ffffff !important;
       height: 10px;
       width: 10px;
       &::before, &::after{
         display: none;
       }
     }
   }
 }
</style>
